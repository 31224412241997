import React from 'react';
import logo from './../assets/images/logo.svg';
import './../App.css';

function PrivacyPolicy() {
  return (
    <div className="App-Privacy">
      <header className="App-header2">
        <img src={logo} className="App-logo" alt="logo" />

        <h1 className="Heading">Privacy Policy</h1>

        <div className="privacy-content" style={{ width: '90%', margin: '0 auto', paddingRight: '10px', fontSize: '1rem', lineHeight: '1.6' }}>
          <h2 style={{ fontSize: '1.5rem', marginBottom: '10px' }}>Introduction</h2>
          <p>
            At FlyZen, your privacy is of utmost importance to us. This Privacy Policy outlines how we collect, use, and safeguard your information when you interact with our app and services.
          </p>

          <h2 style={{ fontSize: '1.5rem', marginBottom: '10px' }}>Information We Collect</h2>
          <p>We may collect the following types of information:</p>
          <ul>
            <li><strong>Personal Information:</strong> Email address, name, and contact details for account registration and communication purposes.</li>
            <li><strong>Usage Data:</strong> App interactions, preferences, and activity logs to enhance your experience.</li>
            <li><strong>Location Data:</strong> Optional data to provide relevant flight updates and features.</li>
            <li><strong>Device Information:</strong> Device type, operating system, and browser version to improve compatibility and performance.</li>
          </ul>

          <h2 style={{ fontSize: '1.5rem', marginBottom: '10px' }}>How We Use Your Information</h2>
          <p>The information we collect is used for the following purposes:</p>
          <ul>
            <li>To provide, maintain, and improve our services.</li>
            <li>To customize your experience and deliver personalized features.</li>
            <li>To send important updates, newsletters, and promotional offers.</li>
            <li>To comply with legal obligations and resolve disputes.</li>
          </ul>

          <h2 style={{ fontSize: '1.5rem', marginBottom: '10px' }}>Sharing Your Information</h2>
          <p>
            We do not sell your personal information. We may share your data with trusted third parties only in the following cases:
          </p>
          <ul>
            <li>With service providers for app functionality (e.g., hosting, analytics, and customer support).</li>
            <li>To comply with legal obligations, enforce our policies, or protect against fraud or security threats.</li>
          </ul>

          <h2 style={{ fontSize: '1.5rem', marginBottom: '10px' }}>Your Rights</h2>
          <p>
            Depending on your location, you may have the following rights:
          </p>
          <ul>
            <li>Access: Request a copy of your personal data.</li>
            <li>Correction: Update or correct inaccuracies in your data.</li>
            <li>Deletion: Request deletion of your personal data.</li>
            <li>Restriction: Restrict the processing of your data under certain conditions.</li>
            <li>Objection: Object to processing based on legitimate interests.</li>
            <li>Portability: Request transfer of your data to another service provider.</li>
          </ul>
          <p>
            To exercise your rights, contact us at <a href="mailto:support@flyzen.app">support@flyzen.app</a>.
          </p>

          <h2 style={{ fontSize: '1.5rem', marginBottom: '10px' }}>Data Security</h2>
          <p>
            We implement robust security measures to protect your data. However, no system is entirely foolproof. We encourage you to take precautions to safeguard your information, such as using strong passwords.
          </p>

          <h2 style={{ fontSize: '1.5rem', marginBottom: '10px' }}>Retention of Data</h2>
          <p>
            We retain your personal data only as long as necessary for the purposes outlined in this policy or as required by law.
          </p>

          <h2 style={{ fontSize: '1.5rem', marginBottom: '10px' }}>Children's Privacy</h2>
          <p>
            Our app is not intended for children under the age of 13, and we do not knowingly collect personal information from them. If we discover such data, we will promptly delete it.
          </p>

          <h2 style={{ fontSize: '1.5rem', marginBottom: '10px' }}>Third-Party Services</h2>
          <p>
            Our app may contain links to third-party websites or services. We are not responsible for their privacy practices, and we encourage you to review their policies before providing any information.
          </p>

          <h2 style={{ fontSize: '1.5rem', marginBottom: '10px' }}>Changes to This Policy</h2>
          <p>
            We may update this Privacy Policy periodically. Changes will be posted on this page with an updated effective date. We encourage you to review this policy regularly.
          </p>

          <h2 style={{ fontSize: '1.5rem', marginBottom: '10px' }}>Contact Us</h2>
          <p>
            If you have questions or concerns about this Privacy Policy, contact us at <a href="mailto:support@flyzen.app">support@flyzen.app</a>.
          </p>

          <p className="Copyrights">© 2025 FlyZen Inc. All rights reserved.</p>
        </div>
      </header>
    </div>
  );
}

export default PrivacyPolicy;
